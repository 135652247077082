import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography,MenuItem, Select, FormControl, InputLabel  } from '@mui/material';
import axios from 'axios'; 

const estados = [
  {"name": "Acre", "uf": "AC"},
  {"name": "Alagoas", "uf": "AL"},
  {"name": "Amapá", "uf": "AP"},
  {"name": "Amazonas", "uf": "AM"},
  {"name": "Bahia", "uf": "BA"},
  {"name": "Ceará", "uf": "CE"},
  {"name": "Distrito Federal", "uf": "DF"},
  {"name": "Espírito Santo", "uf": "ES"},
  {"name": "Goiás", "uf": "GO"},
  {"name": "Maranhão", "uf": "MA"},
  {"name": "Mato Grosso", "uf": "MT"},
  {"name": "Mato Grosso do Sul", "uf": "MS"},
  {"name": "Minas Gerais", "uf": "MG"},
  {"name": "Pará", "uf": "PA"},
  {"name": "Paraíba", "uf": "PB"},
  {"name": "Paraná", "uf": "PR"},
  {"name": "Pernambuco", "uf": "PE"},
  {"name": "Piauí", "uf": "PI"},
  {"name": "Rio de Janeiro", "uf": "RJ"},
  {"name": "Rio Grande do Norte", "uf": "RN"},
  {"name": "Rio Grande do Sul", "uf": "RS"},
  {"name": "Rondônia", "uf": "RO"},
  {"name": "Roraima", "uf": "RR"},
  {"name": "Santa Catarina", "uf": "SC"},
  {"name": "São Paulo", "uf": "SP"},
  {"name": "Sergipe", "uf": "SE"},
  {"name": "Tocantins", "uf": "TO"}
]

const EnderecoForm = ({ user, onSubmit }) => {
  const [cep, setCep] = useState('');
  const [rua, setRua] = useState('');
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [numero, setNumero] = useState('');
  const [frete, setFrete] = useState(0.00)

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Função auxiliar para disparar evento personalizado
    const dispararErro = (mensagem) => {
      const eventoPersonalizado = new CustomEvent('erro', {
        detail: { mensagem },
      });
      document.dispatchEvent(eventoPersonalizado);
    };
  
    // Validação dos campos obrigatórios
    if (!cep) {
      dispararErro('CEP é obrigatório');
      return;
    }
    if (!numero) {
      dispararErro('Número é obrigatório');
      return;
    }
    if (!cidade) {
      dispararErro('Cidade é obrigatória');
      return;
    }
    if (!bairro) {
      dispararErro('Bairro é obrigatório');
      return;
    }
    if (!cidade) {
      dispararErro('Cidade é obrigatória');
      return;
    }
    if (!estado) {
      dispararErro('Estado é obrigatório');
      return;
    }
  
    // Passa os dados preenchidos para a função onSubmit
    onSubmit({
      cep,
      rua,
      bairro,
      complemento,
      numero,
      cidade,
      estado,
    });
  };
  

  React.useEffect(() => {
    (async () => {
       if (cep) {
        const cepTratado = cep.replace(/\D/g, '')

        if (cepTratado?.length < 8) {
          return
        }
        
        try {
          const dadosCep = (await axios.get(`https://viacep.com.br/ws/${cepTratado}/json/`))?.data
          console.log(dadosCep)
          if (dadosCep) { 
            setBairro(dadosCep.bairro)
            setComplemento(dadosCep.complemento)
            setRua(dadosCep.logradouro)
            setCidade(dadosCep.localidade) 
            setEstado(dadosCep.uf)
          }
        } catch(e) {
          
        }
      }
    })()
   
  }, [cep])

  useEffect(() => {
    if (user) { 
      setCep(user.attributes?.cep?.[0] || '');
      setRua(user.attributes?.logradouro?.[0] || ''); 
      setBairro(user.attributes?.bairro?.[0] || '');
      setComplemento(user.attributes?.complemento?.[0] || '');
      setCidade(user.attributes?.cidade?.[0] || '');
      setNumero(user.attributes?.numero?.[0] || '');
      setEstado(user.attributes?.uf?.[0] || '');
    }
  }, [user])

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 2, width: '100%', maxWidth: 400 }}
    >
      <Typography variant="h6">Formulário de Endereço</Typography>

      <TextField
        label="CEP"
        value={cep}
        onChange={(e) => setCep(e.target.value)}
        required
        fullWidth
      />
      <TextField
        label="Rua"
        value={rua}
        onChange={(e) => setRua(e.target.value)}
        required
        fullWidth
      />
        <TextField
        label="Número"
        value={numero}
        onChange={(e) => setNumero(e.target.value)}
        required
        fullWidth
      />
      <TextField
        label="Bairro"
        value={bairro}
        onChange={(e) => setBairro(e.target.value)}
        required
        fullWidth
      />
      <TextField
        label="Complemento"
        value={complemento}
        onChange={(e) => setComplemento(e.target.value)}
        fullWidth
      />
      <TextField
        label="Cidade"
        value={cidade}
        onChange={(e) => setCidade(e.target.value)}
        required
        fullWidth
      />
             <FormControl fullWidth required>
        <InputLabel id="estado-label">Estado</InputLabel>
        <Select
          labelId="estado-label"
          id="estado"
          value={estado}
          onChange={(e) => {setEstado(e.target.value); console.log(e.target.value)}}
          label="Estado"
        >
          <MenuItem value="">
            <em>Selecionar</em>
          </MenuItem>
          {estados.map((uf) => (
            <MenuItem sx={{textAlign: 'start'}} key={uf.uf} value={uf.uf}>
              {uf.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>


      <Button type="submit" variant="contained" color="primary">
        Avançar
      </Button>
    </Box>
  );
};

export default EnderecoForm;
