import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './HeroSection.scss';

function HeroSection() {
    const navigate = useNavigate();
    
    return (
        <>
            <div id="faixa">
                <img src="/plate.png" alt="plate" className="plate" />
                <span className="letreiro2">Wi-fi - Bluetooth - Acelerômetro - Temperatura</span>
                <span className="letreiro3">Giroscópio - Umidade - Buzzer - USB-C</span>
                <span className="vejaMais1">
                    <div>
                        <button className="routeButton" onClick={() => navigate('/sd0-more')}>
                            Veja Mais
                        </button>
                    </div>
                </span>
                <div className="purple">
                    <span className="letreiro1">SD-ZERO</span>
                </div>
            </div>
            
            <ToastContainer containerId="toastContainerjs" position="top-center" />
        </>
    );
}

export default HeroSection;