
function SD0Caracteristicas() {
  return (
    <div className='productFeatures'>
        <h3 className="productFeatures__title">SD-ZERO</h3>
        <span className='productFeatures__description'>
            SD-ZERO é a melhor plataforma para seus projetos.
        </span>
        <div className='productFeatures__list'>
          <span className='productFeatures__list-title'>Principais Características</span>
          <ul className='productFeatures__list__items'>
            <li>Wifi</li>
            <li>Bluetooth</li>
            <li>Antena NRF</li>
            <li>Acelerômetro</li>
            <li>Giroscópio</li>
            <li>Temperatura</li>
            <li>Umidade</li>
            <li>Buzzer</li>
            <li>Suporta até 5 servo-motores</li>
          </ul>
        </div>
    </div>
);

}

export default SD0Caracteristicas