import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Button } from '@mui/material';
import React from 'react';
import './ProductComponent.scss';
function ProductComponent({registro, isMobile, setProdutoCarrinho}) {
    const [state, setState] = React.useState({
        open: false,
        vertical: 'center',
        horizontal: 'center',
      });
      
      const { vertical, horizontal, open } = state;
    
      const handleClick = (newState) => () => {
        setState({ ...newState, open: true });
      };
    
      const handleClose = () => {
        setState({ ...state, open: false });
      };
      
    function formatPrice(price) {
        // Converte o preço para número, caso seja passado como string
        let numericPrice = parseFloat(price);
    
        // Verifica se a conversão resultou em um número válido
        if (isNaN(numericPrice)) {
            throw new Error("O valor fornecido não é um número válido.");
        }
    
        // Retorna o preço formatado com duas casas decimais
        return 'R$' + numericPrice.toFixed(2);
    }
    
   

    return (
        <>  
           
                <div className="cardProduto">
                    <div className='centralizado'> 
                        <span className='title'>{registro.nome}</span>
                        <img className='imgp' width={'75%'} src={registro.cloudUrl} alt='pd'></img>
      
                    
                        <div className='infoMerchant'>
                        {!isMobile && (<><span className='description'>
                                {registro.descricao}
                            </span> </>)}
                            <span className='price'>
                                {formatPrice(registro.preco)}
                            </span>
                            
                        </div>
                       
                        
                    </div>
                    <div id='container-button' className='col-12' style={{textAlign: 'center'}}>
                    <Button  variant='contained' className='botaoComprar' onClick={() => setProdutoCarrinho(registro)}  >
                        <AddShoppingCartIcon sx={{marginRight: '5px'}}></AddShoppingCartIcon>
                        <span>Comprar</span></Button>
                    </div>
 
                </div>
       
            
        </>
    )
}

export default ProductComponent