
import CircleIcon from '@mui/icons-material/Circle';

function AreaTecnica() {
    return (
        <>
        <div id="areaTecnicaDiv" >
            <div className='areaTecnica'>
                <div className='itemAreaTecnica'>
                <div className="compreAqui full-width texto-inicio">ESP32</div>
                <div className='lista'>
                    <ul><CircleIcon sx={{ height: '12px' }} /> <span>Microcontrolador Dual-core de 32 bits</span></ul>
                    <ul><CircleIcon sx={{ height: '12px' }} /> <span>Wi-Fi</span></ul>
                    <ul><CircleIcon sx={{ height: '12px' }} /> <span>Bluetooth</span></ul>
                    <ul><CircleIcon sx={{ height: '12px' }} /> <span>Conversores digital analógico de 8 bits</span></ul>
                    <ul><CircleIcon sx={{ height: '12px' }} /> <span>Conversores analógico digital de 12 bits</span></ul>
                    <ul><CircleIcon sx={{ height: '12px' }} /> <span>Comunicação SPI</span></ul>
                    <ul><CircleIcon sx={{ height: '12px' }} /> <span>Comunicação RS232 TTL (USART)</span></ul>
                    <ul><CircleIcon sx={{ height: '12px' }} /> <span>Comunicação I2C</span></ul>
                    <ul><CircleIcon sx={{ height: '12px' }} /> <span>Pinos de PWM</span></ul>
                    <ul><CircleIcon sx={{ height: '12px' }} /> <span>Pinos digitais programáveis como entrada ou saída</span></ul>
                </div>
                </div>
                <div className='itemAreaTecnica'>
                <div className="compreAqui full-width texto-inicio">Sensores e Outros Periféricos</div>
                <div className='lista'> 
                    <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Sensor de temperatura e umidade DTH11 (GPIO 27)</span></ul>
                    <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Giroscópio, acelerômetro e temperatura MPU-6050 (SCL GPIO 22; SDA GPIO 21)</span></ul>
                    <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Buzzer (GPIO 32)</span></ul>
                    <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Led programável (GPIO 14)</span></ul>
                </div>
                </div>
                <div className='itemAreaTecnica'>
                <div className="compreAqui full-width texto-inicio">Conexão</div>
                <div className='lista'>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Acesso a todos os pinos livres do ESP32</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>4 grupos de barra pinos, cada grupo com 6 pinos do ESP32, pinos de alimentação (GND, 3,3V, 5V, e 12V) e comunicação I2C</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Barramento compatível com shields de Arduino</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Encaixe para 4 servos motores</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>USB via CH340, com conector USB Tipo C ou Tipo A</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Barramento compatível com módulo NRF24L01</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Barramento dedicado a comunicação I2C e alimentação, compatível com módulo display OLED e outros dispositivos I2C</span></ul>
                </div>
                </div>
                <div className='itemAreaTecnica'>
                <div className="compreAqui full-width texto-inicio">Fonte</div>
                <div className='lista'> 
                    <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Entrada padrão de 12V, porém, suporta de 7 a 24V</span></ul>
                    <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Regulador de 5V (3A)</span></ul>
                    <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Regulador de 3,3V (0,5A)</span></ul>
                    <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Filtros para eliminar ruídos vindos da rede</span></ul>
                    <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Proteção contra transientes de tensão</span></ul>
                    <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Conector de entrada compatível com fontes chaveadas (Jack), mas que pode ser substituído por borne</span></ul>
                    <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Possibilidade de alimentação de 5V externo</span></ul>
                </div>
                </div>
        </div>
        </div>
        
        </>
    )
}

export default AreaTecnica