import React from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import dayjs from "dayjs";

const StatusPedido = ({ dadosPedido }) => {
  return (
    <Box
      sx={{
        maxWidth: "400px",
        margin: "0 auto",
        p: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 2,
          borderRadius: 2,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}
        >
          Detalhes do Pedido
        </Typography>
        <Typography variant="body1" sx={{ mb: 1, textAlign: "start" }}>
          <strong>Status:</strong> {dadosPedido.status === 'CONFIRMED' ? 'Pago. Enviando ;)' : 'A Pagar'}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1, textAlign: "start" }}>
          <strong>Produto:</strong> {dadosPedido.Produto.nome}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1, textAlign: "start" }}>
          <strong>Quantidade:</strong> {dadosPedido.quantidade}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1, textAlign: "start" }}>
          <strong>Preço Total:</strong>{" "}
          R$ {(dadosPedido.tipo === "pix" ? (dadosPedido.Produto.precoPix * dadosPedido.quantidade) + dadosPedido.valorFrete  : (dadosPedido.Produto.preco  * dadosPedido.quantidade) + dadosPedido.valorFrete).toFixed(2)}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1, textAlign: "start" }}>
          <strong>Tipo de Pedido:</strong> {dadosPedido.tipo.toUpperCase()}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1, textAlign: "start" }}>
          <strong>Data da Cobrança:</strong>{" "}
          {dayjs(dadosPedido.dataCobranca).format("DD/MM/YYYY HH:mm")}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", textAlign: "start" }}
          >
            Endereço
          </Typography>
          <Typography variant="body2" sx={{ mb: 1, textAlign: "start" }}>
            <strong>Rua:</strong> {dadosPedido.rua}, {dadosPedido.numero}
            {dadosPedido.complemento && ` - ${dadosPedido.complemento}`}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1, textAlign: "start" }}>
            <strong>Bairro:</strong> {dadosPedido.bairro}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1, textAlign: "start" }}>
            <strong>Cidade:</strong> {dadosPedido.cidade} - {dadosPedido.estado}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1, textAlign: "start" }}>
            <strong>CEP:</strong> {dadosPedido.cep}
          </Typography>
        </Box>
        <Button
          href={dadosPedido.linkCobranca}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Link do Pagamento
        </Button>
      </Paper>
    </Box>
  );
};

export default StatusPedido;
