import ListAltIcon from '@mui/icons-material/ListAlt';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CarrinhoDialog from '../carrinho/CarrinhoDialog';
import HistoricoDialog from '../historicoPedidos/HistoricoDialog';
import PerfilDialog from '../perfilDialog/PerfilDialog';

import './Header.scss';

function Header() {
    const navigate = useNavigate();

    const iconStyle = {
        color: 'white',
        fontSize: '32px',
        cursor: 'pointer',
        transition: '0.3s ease all'
    };

    const [isPerfilOpen, setPerfilOpen] = useState(false);
    const [isCarrinhoOpen, setCarrinhoOpen] = useState(false);
    const [isHistoricoOpen, setHistoricoOpen] = useState(false);

    const notifyInfo = (message) => toast.info(message);
    const notifySuccess = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message);

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <header className='header'>
            <section className="header__logo">
                <img
                    onClick={() => navigate('/')}
                    alt="logo spartannis"
                    src="/logo.png"
                    className='header__logo-image--web'
                />
                <img
                    onClick={() => navigate('/')}
                    alt="logo spartannis"
                    src="/logo.png"
                    className='header__logo-image--mobile'
                />
            </section>
            <nav className="header__navigation">
              {/*  FIXME: change logic when all routes are developed */}
              {['Eletrônicos', 'Social', 'I3D', 'Influencers', 'Contato'].map((item, index) => (
                <Link key={index} to="/sd0-more" className="header__navigation-item">
                  {item}
                </Link>
              ))}
            </nav>
            <section className="header__actions">
                <div className="header__actions-icons">
                    {localStorage.getItem('token') ? (
                        <>
                          <ListAltIcon
                              onClick={() => setHistoricoOpen(true)}
                              className="hovericone"
                              sx={iconStyle}
                          />
                          {/*<ShoppingCartIcon
                              onClick={() => setCarrinhoOpen(!isCarrinhoOpen)}
                              className="hovericone"
                              sx={iconStyle}
                          />*/}
                          <PersonIcon
                              onClick={() => setPerfilOpen(!isPerfilOpen)}
                              className="hovericone"
                              sx={iconStyle}
                          />
                          <LogoutIcon
                              onClick={handleLogout}
                              className="hovericone"
                              sx={iconStyle}
                          />
                        </>
                    ) : (
                  // FIXME: needs to fix style on login page
                        <div onClick={() => navigate('/acesso')}>
                            <LoginIcon className="hovericone" sx={iconStyle} />
                        </div>
                    )}
                </div>
                <HistoricoDialog
                    chamarNotificacaoErro={notifyError}
                    chamarNotificacaoInfo={notifyInfo}
                    chamarNotificacaoSucesso={notifySuccess}
                    setAbrirModal={setHistoricoOpen}
                    abrirModal={isHistoricoOpen}
                />
                {/*<CarrinhoDialog
                    chamarNotificacaoErro={notifyError}
                    chamarNotificacaoInfo={notifyInfo}
                    chamarNotificacaoSucesso={notifySuccess}
                    setAbrirModal={setCarrinhoOpen}
                    abrirModal={isCarrinhoOpen}
                />*/}
                <PerfilDialog
                    chamarNotificacaoErro={notifyError}
                    chamarNotificacaoInfo={notifyInfo}
                    chamarNotificacaoSucesso={notifySuccess}
                    setAbrirModal={setPerfilOpen}
                    abrirModal={isPerfilOpen}
                />
            </section>
        </header>
    );
}

export default Header;