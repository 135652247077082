import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs, Paper } from '@mui/material';
import Pedido from '../Pedido/Pedido';
import Pagamento from '../Pagamento/Pagamento';
import StatusPedido from '../StatusPedido/StatusPedido';
import { useParams } from 'react-router-dom';
import sparttanisAxios from '../../../../../global/SparttanisAxios';
import EnderecoForm from '../Endereco/EnderecoForm';

const PaginaPedido = () => {
  const {pedidoId} = useParams()
  const [etapa, setEtapa] = useState(0); // 0: Pedido, 1: Pagamento, 2: Status do Pedido
  const [dadosPedido, setDadosPedido] = useState({});
  const [statusPagamento, setStatusPagamento] = useState('');
  const [pedido, setPedido] = useState('')
  const [user, setUser] = useState({})
  const [produto, setProduto] = useState({})

  const handleChange = (event, newValue) => {
    setEtapa(newValue);
  };

  async function pedidoInfo() {
    try {
      const response = await sparttanisAxios.get('/pedidos/' + pedidoId)
      const data = response.data
      setPedido(data) 
      console.log(data)
      if (data.status !== 'aberto') {
        setEtapa(3)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => { 
    if (pedidoId) {

       (async () => { 
        
        try {
          setUser((await sparttanisAxios.get('/user-info?id=' + localStorage.getItem('ntt'))).data)
        } catch (error) {
          
        }
        })() 
      pedidoInfo()
    }
  }, [pedidoId])

  return (
    <Paper elevation={3} sx={{ padding: 3, backgroundColor: '#fff', minHeight: '400px' }}>
      <Box sx={{padding: 0}}>
        <Tabs
          value={etapa}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Pedido" sx={{pointerEvents: 'none'}} />
          <Tab label="Entrega" sx={{pointerEvents: 'none'}} />
          <Tab label="Pagamento" sx={{pointerEvents: 'none'}} disabled={!dadosPedido.nome} />
          <Tab label="Status" sx={{pointerEvents: 'none'}}  />
        </Tabs>
      </Box>
      <Box sx={{ marginTop: 3 }}>
        {etapa === 0 && (
          <Pedido 
            pedido={pedido}
            produtoId={pedido?.produtoId}
            onAvancar={(dados) => { 
              setPedido(dados.pedido);
              setProduto(dados.produto)
              setEtapa(1);
            }}
          />
        )}
        {
          etapa === 1 && (
            <EnderecoForm
              user={user}
              onSubmit={(dados) => { 
                const p = pedido
                p.endereco = dados
                p.quantidade = parseInt(p.quantidade)
                setPedido({...p, ...dados})  
                setEtapa(2)
              }}
            ></EnderecoForm>
          )
        }
        {etapa === 2 && (
          <Pagamento 
            pedido={pedido}
            produto={produto}
            onPagamentoConcluido={(status) => {
              setStatusPagamento(status);
              setEtapa(3);
            }}
          />
        )}
        {etapa === 3 && (
          <StatusPedido
            dadosPedido={pedido} 
          />
        )}
      </Box>
    </Paper>
  );
};

export default PaginaPedido;
