import AreaTecnica from '../components/AreaTecnica'
import SD0Caracteristicas from '../components/SD0Caracteristicas' 
import SD0RealizarPedido from '../components/SD0RealizarPedido copy'
import './VejaMaisSDContainer.scss'
function VejaMaisSDContainer() {

  return (
    <section className='products'>
      <hr className='products__divider'/>
      <h2 className='products__title'>
        Compre Aqui
      </h2>

      <div className='products__details'> 
        <img src='/plate.png' alt='plate' className='products__details-image' ></img>
        <SD0Caracteristicas></SD0Caracteristicas>
        <SD0RealizarPedido></SD0RealizarPedido>
      </div>

      <div className='products__technical'>
        <img alt='info' src='/SDINFORECTANGLE.png' className='products__technical-image'></img>
      </div>

      <h3 className="products__subtitle">Área Técnica</h3>
      <AreaTecnica></AreaTecnica>
    </section>
  )
}

export default VejaMaisSDContainer