import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Header from './components/header/Header';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import Orders from './pages/ecommerce/Orders';
import HeroSection from './pages/HeroSection';
import Login from './pages/Login/Login';
import SDContainer from './pages/SDContainer';
import VejaMaisSDContainer from './pages/VejaMaisSDContainer';
import PaginaPedido from './pages/ecommerce/components/OrderTracking/PaginaPedido/PaginaPedido';
import React from 'react';
const ConditionalContainer = () => {
  const location = useLocation();
  const [exibirHero, setExibirHero] = React.useState(false)

  useEffect(() => {
    const i = setInterval(() => {
        setExibirHero(!window.location.href.includes('order-tracking'))
    }, 200);

    return () => clearInterval(i)
  }, [])

  if (!location.pathname.includes('acesso') && !location.pathname.includes('criar-conta')) {
    return (
      <>
        <Header />
        {exibirHero && 
          (<>
            <HeroSection />
          </>)
        }
        <div className="figContainer">
            <div>.</div>
            <div>.</div>
        </div>
      </>
    );
  }
  return null;
};

function App() {

  useEffect(() => {
    const handleInfo = (e) => {
      chamarNotificacaoInfo(e.detail.mensagem)
    }
    const handleSucesso = (e) => { 
      chamarNotificacaoSucesso(e.detail.mensagem)
    }
    const handleErro = (e) => {
      chamarNotificacaoErro(e.detail.mensagem)
    }
    document.addEventListener('sucesso', handleSucesso)
    document.addEventListener('info', handleInfo)
    document.addEventListener('erro', handleErro)

    return (() => {
      document.removeEventListener('sucesso', handleSucesso)
      document.removeEventListener('info', handleInfo)
      document.removeEventListener('erro', handleErro)
    })
  }, [])
    
  function chamarNotificacaoInfo(mensagem) {
    toast.info(mensagem, {containerId: 'toastAppjs'})
  }
  function chamarNotificacaoSucesso(mensagem) {
    toast.success(mensagem, {containerId: 'toastAppjs'})
  }
  function chamarNotificacaoErro(mensagem) {
    toast.error(mensagem, {containerId: 'toastAppjs'})
  }
  return (
    <Router>
      <ConditionalContainer></ConditionalContainer>
        
        <Routes>
            <Route path="/" element={<VejaMaisSDContainer />} /> 
            <Route path="/orders" element={<Orders />} />
            <Route path='/order-tracking/:pedidoId' element={<PaginaPedido/>}></Route>
            <Route path="/acesso" element={<Login chamarNotificacaoInfo={chamarNotificacaoInfo}></Login>} />
            <Route path='/criar-conta' element={<CreateAccount chamarNotificacaoErro={chamarNotificacaoErro} chamarNotificacaoSucesso={chamarNotificacaoSucesso} chamarNotificacaoInfo={chamarNotificacaoInfo}></CreateAccount>} /> 
            <Route path="/sd0-more" element={<VejaMaisSDContainer />} /> 
        </Routes>
        <ToastContainer containerId={'toastAppjs'} position='top-center' />
    </Router>
  );
}

export default App;
