import { Button } from '@mui/material';
import React from 'react';
import sparttanisAxios from '../global/SparttanisAxios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
function SD0RealizarPedido() {
  const navigate = useNavigate()
  const [produto, setProduto] = useState({})
    function generateRandomSequence() {
        const characters = 'ABCDEFGHIJKLMN0123456789';
        let sequence = '';
        for (let i = 0; i < 6; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            sequence += characters[randomIndex];
        }
        return sequence;
    }
    
    function startGeneratingSequences() {
        const p1 = document.getElementById('p1')
        const p2 = document.getElementById('p2')
        const p3 = document.getElementById('p3')
        setInterval(() => {
            const randomSequence = generateRandomSequence(); 
            p1.textContent = 'R$ ' +  randomSequence
        }, 60);
        setInterval(() => {
            const randomSequence = generateRandomSequence(); 
            p2.textContent = 'R$ ' + randomSequence
        }, 60);
        setInterval(() => {
          const randomSequence = generateRandomSequence(); 
          p3.textContent = 'R$ ' + randomSequence
      }, 60);
    }

    React.useEffect(() => {
        //startGeneratingSequences() 
    }, [])

    useEffect(() => {
      const fetchProduto = async () => {
        try {
          if (!produto?.id) {
            const response = await sparttanisAxios.get("/produto/2");
            setProduto(response.data);
          }
        } catch (error) {
          console.error("Erro ao buscar o produto:", error);
        }
      };
     

      const i = setInterval(() => { 
        
    
          fetchProduto()
      
    
      }, 1500);
 
    }, [produto]);


  async function acessar() {
    try {
      if (localStorage.getItem('cid')) {
        const res = await sparttanisAxios.post('/pedido-acesso', {
          cid: localStorage.getItem('cid'),
          id: produto?.id
        })
        navigate('/order-tracking/' + res.data)
      } else {
        
        const eventoPersonalizado2 = new CustomEvent('erro', {
          detail: { mensagem: 'Você deve estar autenticado(a) para fazer isso!' }
        });
        document.dispatchEvent(eventoPersonalizado2) 

        navigate('/acesso')

      }
    } catch (error) {
      
    }
  }

  return (
    <div className='productPurchase'>
      <section className='productPurchase__availability'>
        <span>Disponibilidade:</span>
        <span id='di' className='productPurchase__availability--warning'>{produto?.quantidadeEstoque > 0 ? `Restam ${produto?.quantidadeEstoque} unidades!` : 'Esgotado'}</span>
      </section>
      <span><span id='p1' className='green pricing'></span> {`R$ ${produto?.preco?.toFixed(2)}`} <span>cartão em até 12x <span id='p3'></span> !</span></span>
      <span style={{flexDirection: 'column'}}><span id='p2' className='green pricing'></span> <span style={{paddingLeft: '10px'}}> {`R$ ${produto?.precoPix?.toFixed(2)}`} no pix</span></span>
      <div className='full-width realizarPedido'>
       {/* <div className='full-width'>
          <span>Frete e prazo (CEP):</span>
          <input placeholder='00000-000' className='full-width'></input>
        </div>
        <div className='full-width'>
          <span>Cupom:</span>
          <input placeholder='0000' className='full-width'></input>
        </div>*/ }
        <Button id='bb' onClick={() => acessar()} disabled={produto?.quantidadeEstoque < 1} className='full-width'  sx={{opacity: 1}} variant='contained' color="info">{produto?.quantidadeEstoque > 0 ? 'Compre Aqui' : 'Esgotado'} </Button>
      </div>
    </div>
  )
}

export default SD0RealizarPedido