import React, { useState } from 'react';
import { Box, Typography, Button, Divider, TextField, MenuItem } from '@mui/material';
import sparttanisAxios from '../../../../../global/SparttanisAxios';
import { useNavigate } from 'react-router-dom';
const Pagamento = ({ pedido, produto }) => {
  const { precoTotal, cep, rua, bairro, complemento, estado, cidade, numero } = pedido;
  const navigate = useNavigate()
  const [freeze, setFreeze] = useState(false)
  // Estado para armazenar a quantidade de parcelas selecionada
  const [parcelas, setParcelas] = useState(1);

  // Função para calcular o valor do frete com base no estado
  const calcularFrete = () => {
    const estadosSudeste = ['RJ', 'MG', 'ES']; // Região Sudeste (exceto SP)
    const estadosSul = ['PR', 'RS', 'SC']; // Região Sul
    const estadosCentroOeste = ['GO', 'MT', 'DF', 'MS']; // Região Centro-Oeste
    const estadosNorte = ['AC', 'AM', 'AP', 'PA', 'RO', 'RR', 'TO']; // Região Norte

    if (estado === 'SP') return 20;
    if (estadosSudeste.includes(estado)) return 30;
    if (estadosSul.includes(estado) || estadosCentroOeste.includes(estado) || estadosNorte.includes(estado)) return 50;

    return 50; // Frete padrão
  };

  const valorFrete = calcularFrete();

  // Handlers para o pagamento
  const onPagarPix = async () => {
    setFreeze(true)
    console.log('Pagamento via Pix iniciado');
    try {
      const response = await sparttanisAxios.post('/pedidos/compra-pix', {
        ...pedido,
        parcelas,
        cid: localStorage.getItem('cid'),
        valorFrete: calcularFrete()
      })
      const eventoPersonalizado2 = new CustomEvent('sucesso', {
        detail: { mensagem: 'Pedido gerado, será aberto em outra página' }
      });
      document.dispatchEvent(eventoPersonalizado2) 
      window.open(response.data)
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    } catch (error) {
      const eventoPersonalizado2 = new CustomEvent('erro', {
        detail: { mensagem: error.response.data === 'Esgotado' ? 'Quantidade acima da disponível. Tente novamente' : 'Não foi possível gerar o pedido. Tente novamente ou recarregue a página' }
      });
      document.dispatchEvent(eventoPersonalizado2) 

      if (error.response.data === 'Esgotado') {
        navigate('/')
      }
      setFreeze(false)
    }
   
  };

  const onPagarCartao = async () => {
    console.log(`Pagamento via Cartão de Crédito iniciado com ${parcelas} parcela(s)`);
    setFreeze(true)
    try {
      const response = await sparttanisAxios.post('/pedidos/compra-credito', {
        ...pedido,
        parcelas,
        cid: localStorage.getItem('cid'),
        valorFrete: calcularFrete()
      })
      const eventoPersonalizado2 = new CustomEvent('sucesso', {
        detail: { mensagem: 'Pedido gerado, será aberto em outra página' }
      });
      document.dispatchEvent(eventoPersonalizado2) 
      window.open(response.data)
      setTimeout(() => {
       window.location.reload()
      }, 3000);
    } catch (error) { 
      const eventoPersonalizado2 = new CustomEvent('erro', {
        detail: { mensagem: error.response.data === 'Esgotado' ? 'Quantidade acima da disponível. Tente novamente' : 'Não foi possível gerar o pedido. Tente novamente ou recarregue a página' }
      });
      document.dispatchEvent(eventoPersonalizado2) 

      if (error.response.data === 'Esgotado') {
        navigate('/')
      }
      setFreeze(false)
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
        padding: 3,
        border: '1px solid #ccc',
        borderRadius: 2,
        boxShadow: 2,
      }}
    >
      <Typography variant="h6">Informações de Pagamento</Typography>

      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <Typography variant="body1">
          Preço total (normal): R$ {(produto.preco * pedido.quantidade)?.toFixed(2)}
        </Typography>
        <Typography variant="body1">
          Preço total no Pix: R$ {(produto.precoPix * pedido.quantidade)?.toFixed(2)}
        </Typography>
        <Divider sx={{ my: 1 }} />
        {produto.preco * pedido.quantidade > produto.precoPix * pedido.quantidade && (
          <Typography variant="body2" color="success.main">
            Economia: R$ {((produto.preco * pedido.quantidade) - (produto.precoPix * pedido.quantidade)).toFixed(2)} com Pix!
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          padding: 2,
          border: '1px solid #ddd',
          borderRadius: 2,
          backgroundColor: '#f9f9f9',
        }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>
          Endereço de Entrega
        </Typography>
        <Typography variant="body2">CEP: {cep}</Typography>
        <Typography variant="body2">Rua: {rua}, Nº {numero}</Typography>
        <Typography variant="body2">Bairro: {bairro}</Typography>
        <Typography variant="body2">Complemento: {complemento || 'Nenhum'}</Typography>
        <Typography variant="body2">
          Cidade: {cidade} - {estado}
        </Typography>
      </Box>

      <Box sx={{ width: '100%', paddingTop: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Valor do Frete: R$ {valorFrete.toFixed(2)}
        </Typography>
      </Box>

      {/* Campo para seleção de parcelas */}
      <Box sx={{ width: '100%', mt: 2 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Escolha a quantidade de parcelas
        </Typography>
        <TextField
          select
          fullWidth
          value={parcelas}
          onChange={(e) => setParcelas(Number(e.target.value))}
          variant="outlined"
          label="Parcelas"
        >
          {Array.from({ length: 12 }, (_, i) => i + 1).map((num) => (
            <MenuItem key={num} value={num}>
              {num} parcela{num > 1 ? 's' : ''}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box sx={{ pointerEvents: freeze ? 'none' : 'all', opacity: freeze ? '0.6' : '1', display: 'flex', gap: 2, mt: 3 }}>
        <Button variant="contained" color="success" onClick={onPagarPix}>
          Pagar no Pix
        </Button>
        <Button variant="contained" color="primary" onClick={onPagarCartao}>
          Pagar no Cartão de Crédito
        </Button>
      </Box>
    </Box>
  );
};

export default Pagamento;
