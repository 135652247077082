import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Divider } from '@mui/material';
import sparttanisAxios from '../../../../../global/SparttanisAxios';

const Pedido = ({ onAvancar, produtoId, pedido }) => {
  const [nome, setNome] = useState(''); 
  const [produto, setProduto] = useState('');
  const [quantidade, setQuantidade] = useState(pedido?.quantidade || 1);
  const [precoTotal, setPrecoTotal] = useState(0);
  const [precoPixTotal, setPrecoPixTotal] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();

    (async () => {
      
      const p = await sparttanisAxios.get(`/produto/${produtoId}`)
      console.log(p)
      if (quantidade < 1 ||  quantidade > p.data?.quantidadeEstoque  ) {
        const eventoPersonalizado = new CustomEvent('erro', {
          detail: { mensagem: 'Você não pode levar essa quantidade' }
        });
        document.dispatchEvent(eventoPersonalizado)
        setQuantidade(1)
        return
      }
   
      pedido.quantidade = quantidade
      onAvancar({ pedido, produto });

    })()
    
  };

  async function produtoInfo() {
    const response = await sparttanisAxios.get(`/produto/${produtoId}`);
    setProduto(response?.data);
  }

  useEffect(() => {
    if (produtoId) {
      produtoInfo();
    }
  }, [produtoId]);

  useEffect(() => {
    if (produto) {  
      setNome(produto.nome);
      setPrecoTotal(produto.preco * quantidade); // Calcula o preço total
      setPrecoPixTotal(produto.precoPix * quantidade); // Calcula o preço total com Pix
    }
  }, [produto, quantidade]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 2 }}
    >
      <Typography variant="h6">Informações do Pedido</Typography>

      <Box
        component="img"
        sx={{
          width: '250px',
          borderRadius: 2,
          boxShadow: 3,
        }}
        alt={nome}
        src={produto.cloudUrl}
      />
      <TextField
        label="Produto"
        value={nome}
        required
        fullWidth
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        label="Quantidade"
        value={quantidade}
        onChange={(e) => setQuantidade(e.target.value)}
        required
        fullWidth
        min={1}
        type="number"
      />

      {/* Exibição de Preços */}
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <Typography variant="body1">
          Preço normal (unitário): {produto.preco ? `R$ ${produto.preco.toFixed(2)}` : 'Carregando...'}
        </Typography>
        <Typography variant="body1">
          Preço à vista no Pix (unitário): {produto.precoPix ? `R$ ${produto.precoPix.toFixed(2)}` : 'Carregando...'}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body1">
          Preço total (normal): R$ {precoTotal.toFixed(2)}
        </Typography>
        <Typography variant="body1">
          Preço total no Pix: R$ {precoPixTotal.toFixed(2)}
        </Typography>

        {/* Economia no Pix */}
        {precoTotal > precoPixTotal && (
          <Typography variant="body2" color="success.main" sx={{ mt: 1 }}>
            Economia: R$ {(precoTotal - precoPixTotal).toFixed(2)} com Pix!
          </Typography>
        )}
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" color="text.secondary">
          Com possibilidade de parcelamento!
        </Typography>
      </Box>

      <Button type="submit" variant="contained">
        Avançar
      </Button>
    </Box>
  );
};

export default Pedido;
